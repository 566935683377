import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
// Components
import Header from '../components/Header'
import Work from '../components/Work'
// import About from '../components/about'
import Skills from '../components/skills'
import Footer from '../components/Footer'

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Grace Cheng" />
    <Header></Header>
    <Work></Work>
    <Skills></Skills>
    {/* <About></About> */}
    <Footer></Footer>
  </Layout>
)

export default IndexPage
