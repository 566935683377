import React from 'react'

const Card = ({ heading, paragraph, imgUrl, projectLink }) => {
  return (
    <div
      className="card"
      style={{
        backgroundImage: 'url(' + imgUrl + ')',
      }}
    >
      <div className="content">
        <h1 className="header">{heading}</h1>
        <p className="text">{paragraph}</p>
        {projectLink && (
          <a
            href={projectLink ?? '#'}
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            Explore
          </a>
        )}
      </div>
    </div>
  )
}

export default Card
