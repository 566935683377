// Web img
import icon from './images/icon.png'
import myImg from './images/photo2.png'
import socavesImg from './images/Project/socaves.jpg'
import trigoveImg from './images/Project/trigove.jpg'
import umpImg from './images/Project/ump.png'
import ddlImg from './images/Project/daydaylearn.png'
import gameffyImg from './images/Project/gameffy.png'
import wespeedImg from './images/Project/wespeed.png'
import gopImg from './images/Project/GoP.jpg'
import earthImg from './images/Project/earthquake.jpg'
import sbyImg from './images/Project/stayBrainYoung.png'
import tenUsImg from './images/Project/10US.png'

// Skills Icons
import htmlIcon from './images/html.svg'
import cssIcon from './images/css.svg'
import reactIcon from './images/react.svg'
import jsIcon from './images/javascript.svg'
import cppIcon from './images/cpp.svg'
import phpIcon from './images/php.svg'
import srnIcon from './images/swift-in-react-native.svg'
import sqlIcon from './images/postgesql.svg'
import designIcon from './images/design.svg'
import videoIcon from './images/Adobe-Premiere-Pro-icon.png'

// Social Icon
import githubIcon from './images/github.svg'
import instagramIcon from './images/instagram.svg'

const data = {
  // Please Do Not Remove The comma(,) after every variable
  // Change The Website Template

  // Header Details ---------------------
  name: 'Grace',
  icon: icon,
  headerTagline: [
    //Line 1 For Header
    'Develop.',
    //Line 2 For Header
    'Design.',
    //Line 3 For Header
    'Game.',
  ],
  // Header Paragraph
  headerParagraph: 'Love creating things and solving challenges.',

  // Contact Email
  contactEmail: 'hello@thegracecheng.com',
  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: 'SOCAVES',
      para: 'Programming Project',
      imageSrc: socavesImg,
      url: 'https://socaves.com/',
    },
    {
      title: 'Trigove',
      para: 'HCI Design Project',
      imageSrc: trigoveImg,
      url: 'https://zqbvut.axshare.com/',
    },
    {
      title: 'UMP',
      para: 'Part-time work',
      imageSrc: umpImg,
      url: '',
    },
    {
      title: 'Daydaylearn',
      para: 'Just for fun',
      imageSrc: ddlImg,
      url: 'https://www.figma.com/file/EMkXIGTUqd8vwhvATCz66J/Web-1.0',
    },
    {
      title: 'Gameffy',
      para: 'Internship',
      imageSrc: gameffyImg,
      url: 'https://gameffy.com/',
    },
    {
      title: 'WESPEED',
      para: 'Internship',
      imageSrc: wespeedImg,
      url: 'https://apps.apple.com/hk/app/wespeed/id1151418159/',
    },
    {
      title: 'Game of Privacy',
      para: '2018 Personal Privacy Defender Apps Design Competition',
      imageSrc: gopImg,
      url: '',
    },
    {
      title: 'Earthquake Detector',
      para: '2017 Earthquake Detector Design Competition',
      imageSrc: earthImg,
      url: '',
    },
    {
      title: 'Stay Brain Young',
      para: '2016 I am Inventor Competition',
      imageSrc: sbyImg,
      url: '',
    },
    {
      title: '10US',
      para: '2015 Invention for Schools Contest',
      imageSrc: tenUsImg,
      url: '',
    },

    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],
  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "My journal of learning programming and cinematography started in 2014. My primary school taught me some basic 2D animation while I'm interested in playing video games. I began to think that computer animations were so cool!",
  aboutParaTwo:
    'Also, I have a dream of being a game developer. Around 2015-2018, mobile games became very popular. A lot of game developers are trying to make some mobile games. Those game inspired me to know more about app development.',
  aboutParaThree:
    'Moreover, I noticed a good game needs to have a good story. Learning cinematography helps me a lot to get better in storytelling. Therefore, I like to join some competitions in the past few years to have fun while learning. All the projects show how I learnt different things step by step. The projects that I mentioned above taught me so much. I am proud of them :)',
  aboutImage: myImg,
  //   End About Section ---------------------

  // Skills Section ---------------
  //   Import Icons from the top and link it here
  skills: [
    {
      img: cppIcon,
      para: '',
    },
    {
      img: htmlIcon,
      para: '',
    },
    {
      img: cssIcon,
      para: '',
    },
    {
      img: jsIcon,
      para: '',
    },
    {
      img: reactIcon,
      para: '',
    },
    {
      img: phpIcon,
      para: '',
    },
    {
      img: sqlIcon,
      para: '',
    },
    {
      img: srnIcon,
      para: '',
    },
    {
      img: videoIcon,
      para: '',
    },
    {
      img: designIcon,
      para: '',
    },
  ],
  // End Skills Section --------------------------

  //   Contact Section --------------
  // contactSubHeading: "Let's create your next experience together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: 'https://github.com/gra-ch' },
    {
      img: instagramIcon,
      url: 'https://www.instagram.com/_chengrace',
    },
  ],
  // End Contact Section ---------------
}

export default data
